<template>
  <div>
    <div class="static-container">
      <div class="static-title">{{ $trans('关于', '关于') }}</div>
      <div class="static-p">
        {{
          $trans(
            '【上殊房】——听见艺术最细腻的声音',
            '【上殊房】——听见艺术最细腻的声音'
          )
        }}
      </div>
      <div class="static-p">
        {{
          $trans(
            '“上”意指开始、行动、积极起来。我们致力于将每一件艺术品，以多维度的方式呈现于您的眼前。而“殊”则是特别，将每一件作品的殊胜之处，背后的细腻情感传达到您的心里。众多艺术家们将与上殊房一起缔造一个更丰富多彩的多维空间，呈现众多杰出艺术作品的进行时和在新的宇宙空间里的将来时。希望喜欢、热爱艺术的你，成为上殊房的一份子，一起去聆听艺术最细腻的声音。将更多的欢喜和慰藉带给自己，也带给身边的人。',
            '“上”意指开始、行动、积极起来。我们致力于将每一件艺术品，以多维度的方式呈现于您的眼前。而“殊”则是特别，将每一件作品的殊胜之处，背后的细腻情感传达到您的心里。众多艺术家们将与上殊房一起缔造一个更丰富多彩的多维空间，呈现众多杰出艺术作品的进行时和在新的宇宙空间里的将来时。希望喜欢、热爱艺术的你，成为上殊房的一份子，一起去聆听艺术最细腻的声音。将更多的欢喜和慰藉带给自己，也带给身边的人。'
          )
        }}
      </div>
    </div>
    <Footer />
  </div>
</template>
<script>
import Footer from '@/components/layout/Footer.vue'
export default {
  components: {
    Footer,
  },
}
</script>
<style scoped>
.static-container {
  width: 800px;
  padding: 100px 0;
  margin: 0 auto;
}
.static-title {
  font-family: 'myCoolFontMedium';
  font-size: 20px;
  margin-bottom: 60px;
}
.static-p {
  font-size: 14px;
  line-height: 34px;
}
</style>
